<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menu"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menu"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="date = parseDate(endDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.agentName"
                    :label="$t('entity.agent')"
                    @keyup.enter="getVoucherList"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.voucherName"
                    :label="$t('voucherUsed.voucherName')"
                    @keyup.enter="getVoucherList"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVoucherList()"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('report.supplier.voucherDistributionStatisticsToAgentsTitle')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create check voucher -->
            <v-btn
              v-if="!isEntityDisabled()"
              color="success"
              dark
              style="float: right;"
              @click="onExportExcel()"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              <span style="text-transform: none;">{{
                $t("voucherReport.exportExcel")
              }}</span>
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherReportHeaders"
            :items="voucherReportList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in voucherReportHeaders"
                :key="header.value + index"
                class="text-xs-center"
              >
                <div
                  v-if="header.value == 'price'"
                  style="max-height: 150px; overflow: auto; white-space: nowrap;"
                >
                  <p>
                    {{ formatMoney(item[header.value]) }}
                  </p>
                </div>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import XLSX from 'xlsx'

export default {
  data: () => ({
    voucherReportHeaders: [
      { text: 'common.nonumber', value: 'stt', align: 'center' },
      { text: 'voucherTransferRequest.receivingAgent', value: 'receivingAgent', align: 'center' },
      { text: 'voucherUsed.voucherName', value: 'voucherName', align: 'center' },
      { text: 'evoucher.issuance.workpiece', value: 'issuanceCode', align: 'center' },
      {
        text: 'evoucher.quantity',
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'voucherUsed.price',
        value: 'price',
        align: 'center'
      },
      {
        text: 'report.supplier.dateAction',
        value: 'dateAction',
        align: 'center'
      },
      { text: 'report.supplier.implementationStaff', value: 'implementationStaff', align: 'center' },
      {
        text: 'report.staffEmail',
        value: 'staffEmail',
        align: 'center'
      }
      // {
      //   text: 'report.supplier.numberOfCustomersReceived',
      //   value: 'numberOfCustomersReceived',
      //   align: 'center'
      // },
      // {
      //   text: 'report.supplier.numberOfCustomersUsed',
      //   value: 'numberOfCustomersUsed',
      //   align: 'center'
      // }
    ],
    voucherReportList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    search: {
      voucherName: null,
      agentName: null
    },
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    minStartDate: null,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    minEndDate: null,
    exportExcelData: {
      headerList: [
        'common.nonumber',
        'voucherTransferRequest.receivingAgent',
        'voucherUsed.voucherName',
        'evoucher.issuance.workpiece',
        'evoucher.quantity',
        'voucherUsed.price',
        'report.supplier.dateAction',
        'report.supplier.implementationStaff',
        'report.staffEmail',
        'report.supplier.numberOfCustomersReceived',
        'report.supplier.numberOfCustomersUsed'
      ],
      dataList: []
    }
  }),
  computed: {
    ...mapGetters([
      'GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS_DATA'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD')
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS_DATA () {
      let res = this.GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let data = res.results
      this.voucherReportList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          receivingAgent: data[i].to_entity_data_info.name,
          voucherName: data[i].workpiece.name,
          issuanceCode: functionUtils.concatSuffixPrefix(data[i].issuance),
          quantity: data[i].quantity,
          price: data[i].workpiece.value,
          implementationStaff: data[i].user_created_info.full_name,
          staffEmail: data[i].user_created_info.email,
          // numberOfCustomersReceived: data[i].quantity_distributed_to_customer,
          dateAction: this.formatDate(data[i].date_created)
        }
        this.voucherReportList.push(obj)
      }
    }
  },
  created () {
    this.getVoucherList()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date)
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherList()
    },
    getVoucherList: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          includeDisabled: true,
          voucherName: this.search.voucherName,
          agentName: this.search.agentName,
          startDate: !functionUtils.isEmptyString(this.startDateFormatted)
            ? dateUtils.formatDate(this.startDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          endDate: !functionUtils.isEmptyString(this.endDateFormatted)
            ? dateUtils.formatDate(this.endDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS(filter)
        .then(
          function () {
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    onExportExcel: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        params: {
          includeDisabled: true,
          voucherName: this.search.voucherName,
          agentName: this.search.agentName,
          startDate: !functionUtils.isEmptyString(this.startDateFormatted)
            ? dateUtils.formatDate(this.startDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          endDate: !functionUtils.isEmptyString(this.endDateFormatted)
            ? dateUtils.formatDate(this.endDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS(filter)
        .then(
          function (res) {
            this.isLoading = false
            let data = res.data
            let dataList = this.getDataExcelJson(
              this.exportExcelData.headerList,
              data
            )
            this.createExcelFile(dataList)
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    getDataExcelJson: function (headerList, dataList) {
      let resultList = []

      //
      // let headerListLength = headerList.length
      let nonumberHeader = headerList[0]
      let receivingAgentHeader = headerList[1]
      let voucherNameHeader = headerList[2]
      let voucherIssuanceHeader = headerList[3]
      let quantityHeader = headerList[4]
      let priceHeader = headerList[5]
      let dateActionHeader = headerList[6]
      let implementationStaffHeader = headerList[7]
      let staffEmailHeader = headerList[8]
      // let numberOfCustomersReceivedHeader = headerList[9]
      // let numberOfCustomersUsedHeader = headerList[10]

      dataList.forEach((data, index) => {
        let item = {
          [this.$t(nonumberHeader)]: index + 1,
          [this.$t(receivingAgentHeader)]: data.to_entity_data_info.name,
          [this.$t(voucherNameHeader)]: data.workpiece.name,
          [this.$t(voucherIssuanceHeader)]: functionUtils.concatSuffixPrefix(data.issuance),
          [this.$t(quantityHeader)]: data.quantity,
          [this.$t(priceHeader)]: data.workpiece.value,
          [this.$t(dateActionHeader)]: this.formatDate(data.date_created),
          [this.$t(implementationStaffHeader)]: data.user_created_info.full_name,
          [this.$t(staffEmailHeader)]: data.user_created_info.email
          // [this.$t(numberOfCustomersReceivedHeader)]: data.quantity_distributed_to_customer
          // [this.$t(numberOfCustomersUsedHeader)]: data.voucher_usages_count,
        }
        resultList.push(item)
      })
      return resultList
    },
    createExcelFile: function (jsonData) {
      let dataList = []

      dataList = jsonData
      // export json to Worksheet of Excel
      // only array possible
      var assetFile = XLSX.utils.json_to_sheet(dataList)
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, assetFile, 'ReportVoucherCreatedSheet') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, 'ReportVoucherCreated.xlsx') // name of the file is 'book.xlsx'
    },
    ...mapActions([
      'GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS'
    ])
  }
}
</script>

<style></style>
